
@import url("https://fonts.googleapis.com/css2?family=Days+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.container{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  position: relative;
}

.alert{
  position: fixed !important;
  z-index: 99999 !important;
  display: inline-block !important;
}

.alerts-container{
  position: fixed !important;
  z-index: 99999 !important;
  display: inline-block !important;
  margin: 0 auto !important;
  width: 100% !important;

}

.image-container {
  position: relative;
  width: 8rem;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  transition: .5s ease;
  border-radius: 10px;
  background-color: #000;
}

.image-container:hover .img-overlay {
  opacity: 0.5;
}

.img-overlay svg {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay-top-half {
  height: 70%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 30%;
  opacity: 0;
  cursor: pointer;
  transition: .5s ease;
  border-radius: 10px;
  background-color: #000;
}

.overlay-bottom-half {
  height: 30%;
  position: absolute;
  top: 70%;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
  transition: .5s ease;
  border-radius: 10px;
  background-color: red;
}

.overlay-bottom-half svg {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.overlay-top-half svg {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.overlay-bottom-half:hover,
.overlay-top-half:hover {
  opacity: 0.5 !important;
}

.overlay-both:hover .overlay-top-half {
  opacity: 0.2;
}

.overlay-both:hover .overlay-bottom-half {
  opacity: 0.2;
}


.main-btn{
  background-color: rgb(117, 1, 135) !important;
}

button {
  border-width: 0px !important;
  padding: 10px !important;
  font-weight: 300 !important;
  font-family: "Roboto" !important;
  border-radius: 5px !important;
  margin-top: 10px !important;
  transition: 0.4s !important;
  cursor: pointer !important;
  border: 1px solid rgb(117, 1, 135) !important;
  margin-right: 10px !important;
}

button:hover {
  color: rgb(233, 89, 255) !important;
  background-color: #ffffff !important;
}

.h5{
  margin-bottom: 15px !important;
}

.image-container{
  width: 150px !important;
  height: 150px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon{
  background-color: black;
  border-radius: 5px;
}


.card{
  margin-bottom: 10px;
}

.App-link {
  color: #61dafb;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: block;
  margin:15px auto;
  position: fixed;
  z-index: 9999;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  left: 50%; /* Center horizontally */
  top: 50%; /* Center vertically */
  margin-left: -50px; /* Offset by half width */
  margin-top: -50px; /* Offset by half height */
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(117, 1, 135);
  width: 16px;
  height: 16px;
  transform: translate(-50%, 50%);
  border-radius: 50%;
}
.loader::before {
  left: auto;
  right: 0;
  background: #FFF;
  transform: translate(50%, 100%);
}

.editHeaderText{
  font-size: 20px !important;
  font-weight: 400 !important;
  border-radius: 40px !important;
  margin-bottom: 10px;
  padding: 20px !important;
  width: fit-content;
  align-self: center;
}


@keyframes rotation {
0% {
  transform: rotate(0deg);
}
100% {
  transform: rotate(360deg);
}
} 
  
.overlay {
  position: fixed; /* Fixed position to cover the entire viewport */
  top: 0;
  z-index: 9999;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.503); /* Semi-transparent gray background */
  z-index: 999; /* Ensure it's below the loader */
}

.carousel-indicators{
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
